import { DEFAULT_CHAIN_ID } from 'config/chains'
import { encodeReferralCode, getReferralCodeOwner } from 'domain/referrals'
import { BigNumber, BigNumberish } from 'ethers'
import {
  MAX_REFERRAL_CODE_LENGTH,
  REFERRAL_CODE_QUERY_PARAM,
  USD_DECIMALS,
  getTwitterIntentURL,
  isAddressZero,
} from 'gmx/lib/legacy'
import { formatAmount } from 'gmx/lib/numbers'
import { getRootUrl } from 'gmx/lib/url'

export const REFERRAL_CODE_REGEX = /^\w+$/ // only number, string and underscore is allowed
export const REGEX_VERIFY_BYTES32 = /^0x[0-9a-f]{64}$/

export function isRecentReferralCodeNotExpired(referralCodeInfo: any) {
  const REFERRAL_DATA_MAX_TIME = 60000 * 5 // 5 minutes
  if (referralCodeInfo.time) {
    return referralCodeInfo.time + REFERRAL_DATA_MAX_TIME > Date.now()
  }
}

type DeserializeBigIntInObject<T> = {
  [P in keyof T]: T[P] extends { type: 'bigint'; value: bigint }
    ? bigint
    : T[P] extends object
      ? DeserializeBigIntInObject<T[P]>
      : T[P]
}

export function deserializeBigIntsInObject<T extends object>(
  obj: T,
): DeserializeBigIntInObject<T> {
  const result: any = Array.isArray(obj) ? [] : {}
  for (const key in obj) {
    const value = obj[key]
    if (
      typeof value === 'object' &&
      value !== null &&
      (('type' in value && value.type === 'bigint') ||
        ('_type' in value && value._type === 'BigNumber'))
    ) {
      if ('value' in value && typeof value.value === 'string') {
        result[key] = BigInt(value.value)
      } else if ('hex' in value && typeof value.hex === 'string') {
        if (value.hex.startsWith('-')) {
          result[key] = BigInt(value.hex.slice(1)) * -1n
        } else {
          result[key] = BigInt(value.hex)
        }
      }
    } else if (value && typeof value === 'object') {
      result[key] = deserializeBigIntsInObject(value)
    } else {
      result[key] = value
    }
  }
  return result
}

export async function getReferralCodeTakenStatus(
  account: string,
  referralCode: string,
  chainId: number,
) {
  const referralCodeBytes32 = encodeReferralCode(referralCode)
  const [ownerzkSyncSepolia] = await Promise.all([
    getReferralCodeOwner(DEFAULT_CHAIN_ID, referralCodeBytes32),
  ])

  const takenOnzkSync =
    !isAddressZero(ownerzkSyncSepolia) && ownerzkSyncSepolia !== account

  const referralCodeTakenInfo: Record<string, any> = {
    both: takenOnzkSync,
    [DEFAULT_CHAIN_ID]: takenOnzkSync,
  }

  if (referralCodeTakenInfo[chainId]) {
    return { status: 'current', info: referralCodeTakenInfo }
  }
  return { status: 'none', info: referralCodeTakenInfo }
}

export function getTierIdDisplay(tierId: string) {
  return Number(tierId) + 1
}

export const tierRebateInfo = {
  0: 5,
  1: 10,
  2: 15,
}

export const tierDiscountInfo = {
  0: 5,
  1: 10,
  2: 10,
}

function areObjectsWithSameKeys(obj1: Object, obj2: Object) {
  return Object.keys(obj1).every((key) => key in obj2)
}

export function deserializeSampleStats(input: string) {
  const parsedData = JSON.parse(input)
  if (!Array.isArray(parsedData)) {
    return []
  }
  return parsedData
    .map((data) => {
      if (!areObjectsWithSameKeys(getSampleReferrarStat(), data)) {
        return null
      }
      return deserializeBigIntsInObject(data)
    })
    .filter(Boolean)
}

export const getSampleReferrarStat = (
  code = '',
  ownerOnOtherNetwork = '',
  account = '',
) => {
  return {
    discountUsd: BigNumber.from(0),
    referralCode: code,
    totalRebateUsd: BigNumber.from(0),
    tradedReferralsCount: 0,
    registeredReferralsCount: 0,
    trades: 0,
    volume: BigNumber.from(0),
    time: Date.now(),
    v1Data: {
      volume: BigNumber.from(0),
      totalRebateUsd: BigNumber.from(0),
      discountUsd: BigNumber.from(0),
    },
    v2Data: {
      volume: BigNumber.from(0),
      totalRebateUsd: BigNumber.from(0),
      discountUsd: BigNumber.from(0),
    },
    ownerOnOtherChain: {
      code: encodeReferralCode(code),
      codeString: code,
      owner: undefined,
      isTaken: !isAddressZero(ownerOnOtherNetwork),
      isTakenByCurrentUser:
        !isAddressZero(ownerOnOtherNetwork) &&
        ownerOnOtherNetwork.toLowerCase() === account.toLowerCase(),
    },
  }
}

export function getUSDValue(value: BigNumberish, decimals = 2) {
  return formatAmount(value, USD_DECIMALS, decimals, true, '0.00')
}

export function getCodeError(value: string) {
  const trimmedValue = value.trim()
  if (!trimmedValue) {
    return ''
  }

  if (trimmedValue.length > MAX_REFERRAL_CODE_LENGTH) {
    return `The referral code can't be more than ${MAX_REFERRAL_CODE_LENGTH} characters.`
  }

  if (!REFERRAL_CODE_REGEX.test(trimmedValue)) {
    return `Only letters, numbers and underscores are allowed.`
  }
  return ''
}

export function getReferralCodeTradeUrl(referralCode: string) {
  return `${getRootUrl()}/earn/?${REFERRAL_CODE_QUERY_PARAM}=${referralCode}`
}

export function getTwitterShareUrl(referralCode: string) {
  const message = [
    'Trying out trading on RFX, up to 50x leverage on $BTC, $ETH 📈',
    'For fee discounts use:',
  ]
  const shareURL = getReferralCodeTradeUrl(referralCode)

  return getTwitterIntentURL(message, shareURL)
}
